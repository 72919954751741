<template lang="pug">
  v-app.external-view(:class="{ 'external-view--fluid': $slots.main }")
    v-main
      slot(name="main")
        v-container.external-view__container
          v-row.align-center.justify-center
            v-col.d-flex.align-center.justify-center
              v-card.external-view__card.px-5.py-7.w-full
                v-card-title.d-flex.align-center.justify-center.pt-4.pb-3
                  a(href="https://trueplay.io/")
                    Logo
                slot
</template>

<script>
  import Logo from '@/assets/images/Logo.svg?inline';

  export default {
    name: 'AuthLayout',
    components: {
      Logo
    }
  };
</script>

<style lang="scss">
  .external-view {
    overflow: hidden;
    color: #2476e5 !important;
    background-size: contain;
    background: #2b4bff url('/images/bg-external.svg') no-repeat center !important;

    &--fluid {
      background-position: 25% center !important;
    }

    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%;
    }

    &__card {
      border-radius: 6px;
      max-width: 480px;
      width: 100%;
      box-shadow: 0 2px 12px -4px rgba(58, 53, 65, 0.1),
        0 2px 12px -4px rgba(58, 53, 65, 0.08),
        0 6px 16px -4px rgba(58, 53, 65, 0.1) !important;
    }

    a {
      color: #2476e5 !important;
    }

    .v-input {
      height: 86px;
    }
  }
</style>
