
































































import AuthLayout from '@/views/AuthViews/AuthLayout.vue';
import { isEmail, required } from '@/helpers/validation';
import { requestRestorePasswordEmail } from '@/api/Auth';
import { errorToastMessage } from '@/helpers/errorToastMessage';

const TIMER_DURATION = 60 * 1000;

export default {
  name: 'PasswordForgotView',
  data(): any {
    return {
      formValid: true,
      emailIsSend: false,
      email: '',
      endTimerDate: 0,
      secondsLeft: 0,
      emailRules: [(v) => required(v, 'E-mail'), isEmail]
    };
  },
  components: {
    AuthLayout
  },
  computed: {
    successMessage(): string {
      return `Please check the email address ${this.email} for instructions to reset your password.`;
    },
    timeLeftMessage(): string {
      if (this.secondsLeft) {
        const time = `${this.secondsLeft}`.padStart(2, '0');

        return ` (${time} sec)`;
      }

      return '';
    }
  },
  methods: {
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick(this.requestEmail);
    },

    requestEmail(): void {
      requestRestorePasswordEmail(this.email)
        .then(() => {
          this.emailIsSend = true;
        })
        .catch(errorToastMessage)
        .finally(this.resetTimer);
    },

    resetTimer(): void {
      this.endTimerDate = Date.now() + TIMER_DURATION;
      this.secondsLeft = TIMER_DURATION / 1000;
      this.startTimer();
    },

    startTimer(): void {
      const now = Date.now();
      if (now < this.endTimerDate) {
        this.secondsLeft = Math.trunc((this.endTimerDate - now) / 1000);

        setTimeout(() => {
          this.startTimer();
        }, 900);
      } else {
        this.endTimerDate = 0;
        this.secondsLeft = 0;
      }
    }
  }
};
